var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion",class:[
    `accordion--type-${_vm.type}`,
    { 'accordion--is-destroyed': _vm.destroyed }
  ]},[_c('div',{staticClass:"accordion__inner"},[_c('div',{staticClass:"accordion__header",class:{ 'accordion__header--active': _vm.isActive || _vm.destroyed },on:{"click":_vm.toggleAccordion}},[_c('div',{staticClass:"accordion__title hyphenated"},[_vm._t("header")],2),(!_vm.destroyed)?_c('div',{staticClass:"accordion__icon",class:{ 'accordion__icon--active': _vm.isActive }},[_c('InlineSvg',{staticClass:"accordion__icon-svg",attrs:{"src":"triangle-down"}})],1):_vm._e()]),_c('div',{ref:"accordionBody",staticClass:"accordion__body",class:{
        'accordion__body--active': _vm.isActive || _vm.destroyed,
        'accordion__body--hidden': !_vm.isActive && !_vm.destroyed
      },style:({
        '--transition-time': `${_vm.animationTime}ms`,
        '--content-padding': `${_vm.contentPadding}px`
      })},[_c('div',{staticClass:"accordion__body-inner"},[_vm._t("body")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }