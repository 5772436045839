
import cloneDeep from 'lodash-es/cloneDeep'
import axios from 'axios'

let CancelToken = axios.CancelToken
let source = CancelToken.source()

const DEFAULT_FILTER = {
  themes: [],
  participants: [],
  search: '',
  day: '',
}

export default {
  namespaced: true,

  state: {
    filter: cloneDeep(DEFAULT_FILTER),
    nextPageNumber: 0,
    items: []
  },

  getters: {
    FILTER(state) {
      return state.filter
    },

    FORM_DATA(state) {
      const formData = new FormData()

      if (state.filter.day) {
        formData.append('date', state.filter.day)
      }
      if (state.filter.search) {
        formData.append('search', state.filter.search)
      }
      if (state.filter.themes && state.filter.themes.length) {
        formData.append('subject', JSON.stringify(state.filter.themes))
      }
      if (state.filter.participants && state.filter.participants.length) {
        formData.append('participants', JSON.stringify(state.filter.participants))
      }

      return formData
    },

    NEXT_PAGE_NUMBER(state) {
      return state.nextPageNumber
    },

    ITEMS(state) {
      return state.items
    }
  },

  mutations: {
    SET_FILTER_ITEM(state, { name, value }) {
      state.filter[name] = value
    },

    SET_FILTER(state, { filter, withReplace }) {
      state.filter = withReplace ? filter : { ...state.filter, ...filter }
    },

    CLEAR_FILTER(state) {
      state.filter = cloneDeep(DEFAULT_FILTER)
    },

    SET_NEXT_PAGE_NUMBER(state, number) {
      state.nextPageNumber = number
    },

    SET_ITEMS(state, { items, withReplace }) {
      state.items = withReplace ? items : [ ...state.items, ...items ]
    }
  },

  actions: {
    SEND_FILTER_HANDLER({ dispatch }, { withCancel, withNextPage }) {
      if (withCancel) {
        source.cancel()
        source = CancelToken.source()
      }
      dispatch('SEND_FILTER', withNextPage)
    },

    async SEND_FILTER({ commit, getters }, withNextPage) {
      try {
        commit('loading/SET_LOADING', true, { root: true })
        const nextPageNumber = withNextPage ? getters.NEXT_PAGE_NUMBER : 1

        const options = {
          url: '',
          method: 'POST',
          data: getters.FORM_DATA,
          cancelToken: source.token,
          params: {
            pageNumber: nextPageNumber,
            PAGEN_1: nextPageNumber
          }
        }

        const response = await axios(options);

        if (response.status === 200 || response.statusText === 'OK') {
          commit('SET_NEXT_PAGE_NUMBER', response.data.nextPageNumber)
          commit('SET_ITEMS', { items: response.data.items, withReplace: !withNextPage })
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          const date = new Date()
          const h = date.getHours()
          const m = date.getMinutes()
          const s = date.getSeconds()

          console.warn('Запрос отменен:', `${h}:${m}:${s}`)
        }
      } finally {
        commit('loading/SET_LOADING', false, { root: true })
      }
    }
  }
}
