<template>
  <button class="sputnik-header-burger-btn" :class="{ 'sputnik-header-burger-btn--is-active': IS_OPENED_MENU }" @click="toggle">
    <InlineSvg v-if="IS_OPENED_MENU" src="close" />
    <InlineSvg v-else src="burger" />
  </button>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import lockPageScroll from '@/helpers/lockPageScroll.js'

export default {
  computed: {
    ...mapGetters('sputnikHeader', ['IS_OPENED_MENU'])
  },

  methods: {
    ...mapMutations('sputnikHeader', ['SET_IS_OPENED_MENU']),

    toggle() {
      const state = !this.IS_OPENED_MENU
      this.SET_IS_OPENED_MENU(state)
      lockPageScroll(state)

      const method = state ? 'add' : 'remove'
      document.body.classList[method]('header-is-opened')

      if (this.IS_OPENED_MENU) {
        this.$nextTick(() => {
          this.setMenuLinkEvent();
        });
      } else {
        this.removeMenuLinkEvent();
      }
    },
    setMenuLinkEvent() {
      const nav = document.querySelector('.sputnik-header-burger-menu__nav');

      if (nav) {
        const links = nav.querySelectorAll('a');

        links.forEach((link) => {
          link.addEventListener('click', this.toggle);
        });
      }
    },
    removeMenuLinkEvent() {
      const nav = document.querySelector(".sputnik-header-burger-menu__nav");

      if (nav) {
        const links = nav.querySelectorAll("a");

        links.forEach((link) => {
          link.removeEventListener("click", this.toggle);
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes';

$b: '.sputnik-header-burger-btn';

#{$b} {
  @include boxsize(50px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease;
  color: $white-true;
  background-color: $bg-brand;
  z-index: 10;

  &--is-active {
    color: $bg-brand;
    background-color: $white-true;
  }

  @include hover {
    box-shadow: 0 0 17px 5px rgba($white-true, 0.2);
  }

  .inline-svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 22px;
    height: 22px;

    path {
      stroke: currentColor;
    }
  }
}
</style>
