<template>
  <div class="business-program">
    <div class="u-container">
      <div class="business-program__inner">
        <h2 class="business-program__title title-2lg" v-html="title" />
        <BusinessProgramFilter
          class="business-program__filter"
          :filterParticipants="filterParticipants"
          :filterThemes="filterThemes"
          :filterDays="filterDays"
          :lang="lang"
          :notFoundCaption="notFoundLang[lang]"
          @changeFilter="onFilter"
        />
        <BusinessProgramList v-if="ITEMS?.length" :items="ITEMS" :lang="lang" />
        <div v-else class="business-program__not-found">
          {{ notFoundLang[lang] }}
        </div>
        <div ref="infinityTrigger" class="business-program__infinity-trigger" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    initialItems: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: 'ru'
    },
    filterParticipants: {
      type: Array,
      default: () => []
    },
    filterThemes: {
      type: Array,
      default: () => []
    },
    filterDays: {
      type: Array,
      default: () => []
    },
    actionFilter: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    initialNextPageNumber: {
      type: Number,
      default: 0
    },
  },

  data() {
    return {
      notFoundLang: {
        ru: 'Ничего не найдено',
        en: 'Nothing found'
      },
      intersectionObserver: null
    }
  },

  computed: {
    ...mapGetters('program', ['ITEMS', 'NEXT_PAGE_NUMBER']),
  },

  methods: {
    ...mapMutations('program', ['SET_ITEMS', 'SET_FILTER_ITEM', 'SET_NEXT_PAGE_NUMBER']),
    ...mapActions('program', ['SEND_FILTER_HANDLER']),

    onFilter() {
      this.SEND_FILTER_HANDLER({ withCancel: true, withNextPage: false })
    },

    async observerHandler(entries) {
      if (!entries[0].isIntersecting || this.NEXT_PAGE_NUMBER <= 1) {
        return
      }

      this.SEND_FILTER_HANDLER({ withCancel: false, withNextPage: true });
    },

    setDefaultFilterDay() {
      const day = this.filterDays?.[0]?.date || ''
      this.SET_FILTER_ITEM({ name: 'day', value: day })
    },
  },

  mounted() {
    this.intersectionObserver = new IntersectionObserver(this.observerHandler);
    this.intersectionObserver.observe(this.$refs.infinityTrigger);
  },

  created() {
    this.SET_ITEMS({ items: this.initialItems })
    this.SET_NEXT_PAGE_NUMBER(this.initialNextPageNumber)
    this.setDefaultFilterDay()
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-program';

#{$b} {

  // .business-program__title
  &__title {
    margin-bottom: 40px;

    @include tablet {
      margin-bottom: 25px;
    }
  }

  // .business-program__filter
  &__filter {
    margin-bottom: 30px;
  }

  // .business-program__infinity-trigger
  &__infinity-trigger {
    height: 1px;
    opacity: 0;
  }
}
</style>
