<template>
  <div
    class="modal-wrapper"
    @mousedown.self="$emit('close')"
  >
    <div class="modal-wrapper__close" @click="$emit('close')">
      <InlineSvg class="modal-wrapper__close-icon" src="close" />
    </div>
    <div class="modal-wrapper__content">
      <slot />
    </div>
  </div>
</template>

<script>
import lockPageScroll from '@/helpers/lockPageScroll'

export default {
  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.$emit('close')
      }
    })
    this.setScrollLockState(true)
  },

  beforeDestroy() {
    this.setScrollLockState(false)
  },

  methods: {
    setScrollLockState(state) {
      lockPageScroll(state)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.modal-wrapper';

#{$b} {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 998;
  padding: $container-gap;
  background-color: rgba($bg-brand, 0.8);
  overflow: auto;
  backdrop-filter: blur(20px);

  @include low-desktop {
    padding: $container-gap-md;
  }

  @include mobile {
    padding: $container-gap-md;
  }

  // .modal-wrapper__content
  &__content {
    position: relative;
    z-index: 999;
    margin: auto 0;
    width: max-content;
    max-width: 100%;
    display: flex;
    justify-content: center;

    @include mobile {
      width: 100%;
    }
  }

  // .modal-wrapper__close
  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: $white-true;
    color: $black-true;
    transition: all 0.3s ease;
    z-index: 1000;

    @include hover {
      background-color: $black-true;
      color: $white-true;
    }

    // .modal-wrapper__close-icon
    &-icon {
      height: 20px;
      width: 20px;

      svg {
        height: 100%;
        width: 100%;
        path {
          stroke: currentColor;
        }
      }
    }
  }
}
</style>
