<template>
  <ModalWrapper
    v-if="videoCard"
    class="video-block-modal"
    @close="CLOSE_MODAL"
  >
    <div class="video-block-modal__inner" :class="{ 'video-block-modal__inner--is-iframe': !isDefaultVideo && videoCard.iframe }">
      <video
        v-if="isDefaultVideo"
        :src="videoCard.video"
        :poster="videoCard.poster"
        playsinline="playsinline"
        webkit-playsinline="webkit-playsinline"
        preload="auto"
        controls
        class="video-block-modal__video"
      />
      <div v-else-if="videoCard.iframe" class="video-block-modal__iframe" v-html="videoCard.iframe" />
    </div>
  </ModalWrapper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('modal', ['MODAL']),

    videoCard() {
      return this.MODAL?.props
    },

    isDefaultVideo() {
      return Boolean(this.videoCard?.video)
    }
  },

  methods: {
    ...mapActions('modal', ['CLOSE_MODAL']),
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.video-block-modal';

#{$b} {

  // .video-block-modal__inner
  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      max-width: 100%;
      max-height: 100%;
    }
  }

  // .video-block-modal__iframe
  &__iframe {
    position: relative;
    width: calc(100vw - ($container-gap * 2));
    padding-top: 56.25%;
    height: auto;

    @include low-desktop {
      width: calc(100vw - ($container-gap-md * 2));
    }

    @include mobile {
      width: 100%;
    }

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
