<template>
  <div class="business-program-tags">
    <div class="business-program-tags__inner">
      <div
        v-for="(tag, i) in tags"
        :key="`business-program-tags__item-${i}`"
        class="business-program-tags__item"
      >
        <div class="business-program-tags__item-name tt-uppercase" v-html="tag" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: () => []
    },
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-program-tags';

#{$b} {

  // .business-program-tags__inner
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
  }

  // .business-program-tags__item
  &__item {
    padding: 3px;

    // .business-program-tags__item-name
    &-name {
      display: inline-flex;
      align-items: center;
      text-align: center;
      color: $bg-brand;
      border: 1px solid $bg-brand;
      border-radius: 32px;
      padding: 4px 12px;
    }
  }
}
</style>
