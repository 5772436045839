<template>
  <div class="business-themes">
    <div class="business-themes__inner">
      <div
        v-for="(col, i) in items"
        class="business-themes__col"
        :key="`business-themes-${i}`"
      >
        <div class="business-themes__col-title text-lg fw-500">
          {{ captionLang[lang] }}
        </div>
        <div v-if="col.caption" class="business-themes__col-caption fw-500" v-html="col.caption" />
        <div class="business-themes__themes">
          <div
            v-for="theme in col.themes"
            :key="`business-themes__theme-${i}-${theme.id}`"
            class="business-themes__theme"
          >
            <CustomCheckbox @input="checked => changeCheckbox(checked, theme.id)" :value="isChecked(theme.id)">
              <span class="business-themes__theme-name fw-500">{{ theme.name }}</span>
              <span class="color-light-blue-2 fw-500">{{ theme.count }}</span>
            </CustomCheckbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    notFoundCaption: {
      type: String,
      default: ''
    },
    lang: {
      type: String,
      default: 'ru'
    },
    filter: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      captionLang: {
        ru: 'IV Конгресс молодых ученых',
        en: 'IV Young scientists congress'
      }
    }
  },

  methods: {
    changeCheckbox(checkbox, id) {
      this.$emit('change', checkbox, id)
    },

    isChecked(id) {
      return this.filter.includes(id)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-themes';

#{$b} {

  // .business-themes__col
  &__col {

    @include mobile {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }

    // .business-themes__col-title
    &-title {
      line-height: 108%;
      margin-bottom: 25px;

      @include tablet {
        min-height: unset;
      }
    }

    // .business-themes__col-caption
    &-caption {
      margin-bottom: 16px;
    }
  }

  // .business-themes__themes
  &__themes {
    @include gridcols(4, 44px);
    row-gap: 24px;

    @include low-desktop {
      @include gridcols(3, 32px);
      row-gap: 24px;
    }
    @include tablet {
      @include gridcols(2, 24px);
    }
    @include mobile {
      display: block;
    }
  }

  // .business-themes__theme
  &__theme {

    @include mobile {
      margin-bottom: 24px;
    }

    // .business-themes__theme-name
    &-name {
      margin-right: 12px;
    }
  }
}
</style>
