<template>
  <div class="responsive-slider" :class="[{ 'is-slider': isSlider, [noSliderClass]: !isSlider }]">
    <template v-if="isSlider">
      <swiper ref="slider" :options="swiperOptions">
        <slot />
      </swiper>
    </template>
    <template v-else>
      <slot />
    </template>
    <template v-if="isSlider && isPagination">
      <div v-show="canSlide" class="slider-pagination" :class="`slider-${this.id}__bullets slider-pagination--theme-${paginationTheme}`" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveSlider',
  inject: ['breakpoint'],
  props: {
    id: {
      type: String,
      default: "0",
    },
    items: {
      type: Object,
      default: () => ({
        desktop: 4,
        low: 3,
        tablet: 2.4,
        mobile: 1.2,
      }),
    },
    theme: {
      type: String,
      default: 'dark',
    },
    isLoop: {
      type: Boolean,
      default: false,
    },
    initOn: {
      type: String,
      default: 'desktop',
    },
    isPagination: {
      type: Boolean,
      default: false
    },
    noSliderClass: {
      type: String,
      default: ''
    },
    spaceBetweenAdaptive: {
      type: Object,
      default: () => ({
        desktop: 16,
        low: 16,
        tablet: 24,
        mobile: 16
      })
    },
    freeMode: {
      type: Boolean,
      default: false
    },
    paginationTheme: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      swiper: null
    }
  },
  computed: {
    isSlider() {
      return (
        (this.initOn === 'desktop' && this.breakpoint.isDesktop) ||
        (this.initOn === 'low' && this.breakpoint.isLow) ||
        (this.initOn === 'tablet' && this.breakpoint.isTablet) ||
        (this.initOn === 'mobile' && this.breakpoint.isMobile)
      );
    },
    canSlide() {
      return this.swiper?.allowSlideNext || this.swiper?.allowSlidePrev
    },
    swiperOptions() {
      return {
        slidesPerView: this.items.mobile,
        slidesPerGroup: this.slidesPerGroup(this.items.mobile),
        loop: this.isLoop,
        watchOverflow: true,
        watchSlidesVisibility: true,
        spaceBetween: this.spaceBetweenAdaptive.mobile,
        freeMode: this.freeMode,
        navigation: {
          prevEl: `.slider-${this.id}__prev`,
          nextEl: `.slider-${this.id}__next`,
          disabledClass: 'is-disabled',
        },
        pagination: {
          el: `.slider-${this.id}__bullets`,
          type: 'bullets',
          bulletClass: 'slider-pagination__dot',
          bulletActiveClass: 'slider-pagination__dot--is-active',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: this.items.tablet,
            slidesPerGroup: this.slidesPerGroup(this.items.tablet),
            spaceBetween: this.spaceBetweenAdaptive.tablet,
          },
          1024: {
            spaceBetween: this.spaceBetweenAdaptive.low,
            slidesPerView: this.items.low,
            slidesPerGroup: this.slidesPerGroup(this.items.low),
          },
          1280: {
            slidesPerView: this.items.desktop,
            slidesPerGroup: this.slidesPerGroup(this.items.desktop),
            spaceBetween: this.spaceBetweenAdaptive.desktop,
          },
        },
        on: {
          init: () => {
            this.$nextTick().then(() => {
              this.swiper = this.$refs.slider?.$swiper
            })
          },
        }
      }
    }
  },
  methods: {
    slidesPerGroup(perView) {
      return typeof perView === 'number'
        ? (Number.isInteger(perView) ? perView : Math.trunc(perView))
        : 1
    },
  },
};
</script>

<style lang="scss">
@use "sass:math";
@import "@/scss/base/includes";

$b: ".responsive-slider";

#{$b} {
  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-pagination-bullets {
    margin-top: 24px;
  }
}
</style>
