<template>
  <div
    class="accordion"
    :class="[
      `accordion--type-${type}`,
      { 'accordion--is-destroyed': destroyed }
    ]"
  >
    <div class="accordion__inner">
      <div
        class="accordion__header"
        :class="{ 'accordion__header--active': isActive || destroyed }"
        @click="toggleAccordion"
      >
        <div class="accordion__title hyphenated">
          <slot name="header" />
        </div>
        <div
          v-if="!destroyed"
          class="accordion__icon"
          :class="{ 'accordion__icon--active': isActive }"
        >
          <InlineSvg class="accordion__icon-svg" src="triangle-down" />
        </div>
      </div>
      <div
        ref="accordionBody"
        class="accordion__body"
        :class="{
          'accordion__body--active': isActive || destroyed,
          'accordion__body--hidden': !isActive && !destroyed
        }"
        :style="{
          '--transition-time': `${animationTime}ms`,
          '--content-padding': `${contentPadding}px`
        }"
      >
        <div class="accordion__body-inner">
          <slot name="body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    isOpened: {
      type: Boolean,
      default: false
    },
    destroyed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isActive: this.isOpened,
      toggleTimeoutId: null,
      animationTime: 300,
      contentPadding: 32,
      animationDelay: 50,
      typeSettings: {
        default: {
          animationTime: 300,
          contentPadding: 32,
          animationDelay: 50
        },
      }
    }
  },

  computed: {
    accordionBodyStyle() {
      return {
        '--transition-time': `${this.animationTime}ms`,
        '--content-padding': `${this.contentPadding}px`
      }
    },
  },

  created() {
    this.setSettingsByType()
  },

  methods: {
    toggleAccordion() {
      if (this.destroyed) {
        return
      }

      this.start(this.$refs.accordionBody)

      setTimeout(() => {
        this.isActive = !this.isActive
      }, this.animationDelay)
    },

    start(el) {
      el.style.maxHeight = `${el.scrollHeight + this.contentPadding}px`

      clearTimeout(this.toggleTimeoutId)
      this.toggleTimeoutId = setTimeout(() => {
        el.style.maxHeight = ''
      }, this.animationTime + this.animationDelay)
    },

    setSettingsByType() {
      if (Object.hasOwn(this.typeSettings, this.type)) {
        for (const key in this.typeSettings[this.type]) {
          const value = this.typeSettings[this.type][key]
          this[key] = value
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/base/includes";

$b: '.accordion';

#{$b} {
  // .accordion__inner
  &__inner {
    position: relative;
  }

  // .accordion__header
  &__header {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;

    #{$b}--is-destroyed & {
      cursor: default;
    }

    &--active {
      #{$b}--type-default & {
        padding-bottom: 40px;

        @include tablet {
          padding-bottom: 24px;
        }
      }
    }

    #{$b}--type-default & {
      padding-left: 40px;
      padding-right: 40px;

      @include tablet {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  // .accordion__title
  &__title {
    margin: 0 24px 0 0;
  }

  // .accordion__icon
  &__icon {
    margin-left: auto;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    #{$b}--type-default & {
      @include boxsize(40px);
      color: $white-true;
      border-radius: 50%;
      padding: 10px;
      background-color: $color-base-origin;
      border: 2px solid $color-base-origin;

      @include mobile {
        @include boxsize(32px);
        padding: 5px;
      }

      @include hover {
        color: $color-base-origin;
        background-color: transparent;
      }
    }

    &--active {
      transform: rotate(180deg);
    }

    // .accordion__icon-svg
    &-svg {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1px;

      svg {
        width: 100%;
        height: 100%;
      }

      path {
        fill: currentColor;
      }
    }
  }

  // .accordion__body
  &__body {
    opacity: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    @include removeScrollBar();

    &--active {
      opacity: 1;
    }

    &--hidden {
      padding: 0 !important;
      max-height: 0 !important;
      pointer-events: none;
      user-select: none;
    }

    // .accordion__body-inner
    &-inner {
      #{$b}--type-default & {
        padding: 40px;
        border-radius: 64px;
        background-color: rgba($color-base-origin, 0.05);

        @include tablet {
          padding: 16px;
          border-radius: 24px;
        }
      }
    }
  }
}
</style>
