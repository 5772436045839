<template>
  <div
    class="business-modal-filter"
    :class="`business-modal-filter--size-${size}`"
    @mousedown.self="close"
  >
    <div class="business-modal-filter__inner">
      <div class="business-modal-filter__close" @click="close">
        <InlineSvg class="business-modal-filter__close-icon" src="u-close" />
      </div>
      <div class="business-modal-filter__header">
        <div class="business-modal-filter__title title-xl">
          Фильтры
        </div>
      </div>
      <div class="business-modal-filter__body">
        <div class="business-modal-filter__body-wrapper">
          <Tabs theme="primary" @change="onChangeTab">
            <Tab selected title="Участники">
              <template #content>
                <div class="business-modal-filter__search">
                  <input
                    type="text"
                    class="business-modal-filter__search-input"
                    :placeholder="searchPlaceholder.participants[lang]"
                    v-model="search"
                  >
                  <InlineSvg class="business-modal-filter__search-icon" src="search" />
                </div>
                <BusinessParticipants
                  v-if="searchedFilterParticipantsItems?.length"
                  :items="searchedFilterParticipantsItems"
                  :filter="scopedFilter.participants"
                  @change="onChangeParticipants"
                />
                <div v-else class="business-modal-filter__not-found">
                  {{ notFoundCaption }}
                </div>
              </template>
            </Tab>
            <Tab title="Темы">
              <template #content>
                <div class="business-modal-filter__search">
                  <input
                    type="text"
                    class="business-modal-filter__search-input"
                    :placeholder="searchPlaceholder.themes[lang]"
                    v-model="search"
                  >
                  <InlineSvg class="business-modal-filter__search-icon" src="search" />
                </div>
                <BusinessThemes
                  v-if="searchedFilterThemesItems?.length"
                  :items="searchedFilterThemesItems"
                  :filter="scopedFilter.themes"
                  @change="onChangeThemes"
                />
                <div v-else class="business-modal-filter__not-found">
                  {{ notFoundCaption }}
                </div>
              </template>
            </Tab>
          </Tabs>
        </div>
      </div>
      <div class="business-modal-filter__footer">
        <div class="business-modal-filter__actions">
          <CustomButton
            class="business-modal-filter__footer-btn"
            theme="primary"
            size="md"
            @click="saveFilter"
          >
            <InlineSvg class="business-modal-filter__actions-icon" src="check-icon" />
            Применить
          </CustomButton>
          <CustomButton
            class="business-modal-filter__footer-btn"
            theme="outline-primary"
            size="md"
            @click="clearFilter"
          >
            Очистить все
          </CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    caption: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: 'ru'
    },
    size: {
      type: String,
      default: 'xl' // xl | md
    },
    filterThemesItems: {
      type: Array,
      default: () => []
    },
    filterParticipantsItems: {
      type: Array,
      default: () => []
    },
    notFoundCaption: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      search: '',
      btnClearCaptionLang: {
        ru: 'Очистить все',
        en: 'Clear all'
      },
      btnAcceptCaptionLang: {
        ru: 'Применить',
        en: 'Apply'
      },
      searchPlaceholder: {
        themes: {
          ru: 'Введите тему',
          en: 'Enter a subject'
        },
        participants: {
          ru: 'Введите имя участника',
          en: 'Enter participants name'
        }
      },
      scopedFilter: {
        participants: [],
        themes: []
      },
    }
  },

  computed: {
    ...mapGetters('program', ['FILTER']),

    searchedFilterThemesItems() {
      return this.getSearchedItems(this.search, this.filterThemesItems, 'themes')
    },

    searchedFilterParticipantsItems() {
      return this.getSearchedItems(this.search, this.filterParticipantsItems, 'items')
    }
  },

  methods: {
    ...mapMutations('program', ['SET_FILTER']),

    close() {
      this.$emit('close')
    },

    setScrollLock(state = true) {
      const delta = window.innerWidth - document.documentElement.clientWidth

      document.body.style.overflow = state ? 'hidden' : ''
      document.body.style.paddingRight = state ? `${delta}px` : ''
    },

    onChangeParticipants(state, id) {
      this.onChangeFilter('participants', state, id)
    },

    onChangeThemes(state, id) {
      this.onChangeFilter('themes', state, id)
    },

    onChangeFilter(name, state, id) {
      const items = state
        ? [...this.scopedFilter[name], id]
        : this.scopedFilter[name].filter(item => item !== id)

      this.scopedFilter = {
        ...this.scopedFilter,
        [name]: items
      }
    },

    clearFilter() {
      this.SET_FILTER({ filter: { themes: [], participants: [] } })
      this.close()
      this.$emit('changeFilter')
    },

    saveFilter() {
      const filter = { themes: this.scopedFilter.themes, participants: this.scopedFilter.participants }
      this.SET_FILTER({ filter })
      this.close()
      this.$emit('changeFilter')
    },

    clearSearch() {
      this.search = ''
    },

    onChangeTab() {
      this.clearSearch()
    },

    getClearedString(string) {
      return string?.trim()?.toLowerCase() || ''
    },

    getSearchedItems(search, originalItems, name) {
      return search
        ? originalItems
          .map(col => {
            const items = col[name].filter(item => this.getClearedString(item.name)?.includes(this.getClearedString(search)))

            return { ...col, [name]: items }
          })
          .filter(col => col[name]?.length)
        : originalItems
    },
  },

  created() {
    this.setScrollLock()
    this.scopedFilter = this.FILTER
  },

  beforeDestroy() {
    this.setScrollLock(false)
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.business-modal-filter';

#{$b} {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba($bg-brand, 0.60);
  backdrop-filter: blur(26px);
  padding: $container-gap;

  @include tablet {
    padding: $container-gap-md;
  }

  // .business-modal-filter__close
  &__close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $black-true;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid rgba($black-true, 0.1);
    padding: 13px;
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 2;

    @include tablet {
      right: 40px;
      top: 40px;
      height: 40px;
      width: 40px;
      padding: 10px;
    }

    @include mobile {
      right: 8px;
      top: 8px;
      width: 31px;
      height: 31px;
      border: 1px solid rgba($white-true, 0.1);
      color: $white-true;
    }

    @include hover {
      color: $black-true;

      #{$b}__close-icon {
        transform: rotate(90deg);
      }
    }

    &-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: currentColor;
        }
      }
    }
  }

  // .business-modal-filter__inner
  &__inner {
    position: relative;
    width: 1232px;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 64px;
    background-color: $white-true;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: auto;

    @include tablet {
      position: static;
      border-radius: 40px;
    }
  }

  // .business-modal-filter__body
  &__body {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    margin-right: 32px;
    margin-bottom: 16px;

    @include tablet {
      margin-right: 12px;
    }

    @include customScrollBar(8px, $white-true, rgba($white-true, 0.1));

    // .business-modal-filter__body-wrapper
    &-wrapper {
      padding-right: 32px;
      padding-left: 64px;

      @include tablet {
        padding-left: 24px;
        padding-right: 12px;
      }
    }
  }

  // .business-modal-filter__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    padding-top: 64px;
    padding-left: 64px;
    padding-right: 64px;

    @include tablet {
      padding-top: 17px;
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 27px;
    }

    @include mobile {
      align-items: unset;
    }

    // .business-modal-filter__header-left
    &-left {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;

      @include mobile {
        margin-right: 0;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start
      }
    }

    // .business-modal-filter__header-right
    &-right {
      display: inline-flex;
      align-items: center;

      @include mobile {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }

    // .business-modal-filter__header-caption
    &-caption {
      margin: 0 50px 0 0;

      @include tablet {
        margin: 0 32px 0 0;
      }

      @include mobile {
        margin: 0 0 16px 0;
      }
    }

    // .business-modal-filter__header-clear
    &-clear {
      cursor: pointer;
    }

    // .business-modal-filter__header-accept
    &-accept {
      margin-right: 27px;

      @include mobile {
        margin: 16px 0 0 0;
      }
    }
  }

  // .business-modal-filter__search
  &__search {
    position: relative;
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 37px;
    }

    // .business-modal-filter__search-input
    &-input {
      width: 100%;
      height: 40px;
      border-radius: 40px;
      color: $black-true;
      box-shadow: 0px 4px 12px 0px rgba(11, 32, 95, 0.10);
      padding: 3px 20px 3px 56px;
    }

    // .business-modal-filter__search-icon
    &-icon {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      color: rgba($black-true, 0.4);
      pointer-events: none;
      touch-action: none;
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        path {
          fill: currentColor;
        }
      }
    }
  }

  // .business-modal-filter__footer
  &__footer {
    margin-top: auto;
    padding: 24px 64px;
    box-shadow: 0px -4px 12px 0px rgba(11, 32, 95, 0.10);

    @include tablet {
      padding: 24px 24px 27px 24px;
    }
  }

  // .business-modal-filter__actions
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: block;

      & > * {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }

    // .business-modal-filter__actions-icon
    &-icon {
      margin-right: 8px;

      path {
        stroke: currentColor;
      }
    }
  }
}
</style>
