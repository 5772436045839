<template>
  <div class="animation-wrapper" :style="rootStyle">
    <div class="animation-wrapper__inner" :style="animationStyle">
      <slot />
    </div>
  </div>
</template>

<script>
import {
  ANIMATE_STYLES,
  Animation,
  ANIMATIONS
} from '@/components/animate/animationController.js'

export default {
  props: {
    animationName: {
      type: String,
      default: ANIMATIONS.FADE_IN_UP
    },

    observerOptions: {
      type: Object,
      default: () => ({
        rootMargin: '0px',
        threshold: [0.15, 0.25, 0.5, 0.75, 1]
      })
    },

    animationTime: {
      type: Number,
      default: 500
    },

    animationDelay: {
      type: Number,
      default: 300
    },

    animationTimeParalax: {
      type: Number,
      default: 500
    },

    deltaPercent: {
      type: Number,
      default: 0.1
    }
  },

  data() {
    return {
      isVisible: false,
      Animation: null,
    }
  },

  computed: {
    animationStyle() {
      const key = this.isVisible ? 'show' : 'hide'
      return {
        ...ANIMATE_STYLES[this.animationName][key],
        '--transition-time': `${this.animationTime}ms`,
      }
    },

    rootStyle() {
      return {
        '--transition-time-paralax': `${this.animationTimeParalax}ms`
      }
    }
  },

  mounted() {
    this.Animation = new Animation({
      target: this.$el,
      animationName: this.animationName,
      observerOptions: this.observerOptions,
      intersectingHandler: this.intersectingHandler,
      scrollHandler: this.scrollHandler,
      paralaxOptions: {
        deltaPercent: this.deltaPercent,
      }
    })
  },

  beforeDestroy() {
    this.Animation?.removeEvents()
  },

  methods: {
    intersectingHandler() {
      setTimeout(() => {
        if (!this.isVisible) {
          this.isVisible = true
        }
      }, this.animationDelay)
    }
  }
}
</script>

<style lang="scss">
$b: '.animation-wrapper';

#{$b} {
  transition: all var(--transition-time-paralax) ease;

  // .animation-wrapper__inner
  &__inner {
    transition: all var(--transition-time) ease;
  }
}
</style>
