<template>
  <div class="video-card" @click="onClick">
    <div class="video-card__inner">
      <div class="video-card__preview">
        <img :src="card.poster" :alt="card.caption" class="video-card__preview-img">
      </div>
      <div class="video-card__main">
        <div v-if="card.caption" class="video-card__caption title-lg title-lg--mob-lg ff-title tt-uppercase" v-html="card.caption" />
        <div class="video-card__btn">
          <InlineSvg src="play" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    card: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    ...mapActions('modal', ['OPEN_MODAL']),

    onClick() {
      this.OPEN_MODAL({ name: 'video-block-modal', props: this.card })
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/base/includes.scss';

$b: '.video-card';

#{$b} {
  height: 100%;
  cursor: pointer;

  // .video-card__inner
  &__inner {
    position: relative;
    height: 100%;
    min-height: 342px;
    color: $white-true;
    border-radius: 8px;
    overflow: hidden;

    @include mobile {
      min-height: 245px;
    }
  }

  @include hover {
    #{$b}__preview {
      transform: scale(1.05)
    }
  }

  // .video-card__preview
  &__preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.5s ease;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
      border-radius: 8px;
    }

    // .video-card__preview-img
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .video-card__main
  &__main {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 2;
    padding: 16px;
  }

  // .video-card__btn
  &__btn {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $white-true;
    transition: all 0.3s ease;

    @include mobile {
      width: 64px;
      height: 64px;
    }

    svg {
      width: 25px;
      height: 25px;
      margin-top: 2px;
      margin-left: 7px;

      @include mobile {
        width: 20px;
        height: 20px;
        margin-top: 5px;
        margin-left: 6px;
      }
    }
  }
}
</style>
